import * as React from "react"
import { Collapse, OverlayTrigger, Tooltip } from "react-bootstrap"
import AxiosInstance from "../../../services/axiosInstance"

const AddEmail = ({
  showAddEmail,
  setShowAddEmail,
  editEmailData,
  setEditEmailData,
  getCommunicationList,
}) => {
  const emailEditId = editEmailData.id ?? false

  const submitImapSetting = e => {
    e.preventDefault()

    const formData = new FormData(e.target)

    if (formData.get("email") === "") {
      return false
    }

    let emailPostData = {
      email: formData.get("email"),
      incoming: formData.get("incoming"),
      outgoing: formData.get("outgoing"),
      password: formData.get("logpass"),
      owner: formData.get("owner"),
      type: formData.get("type"),
      username: formData.get("loguserName"),
      port: formData.get("port"),
      sslPort: formData.get("port_ssl"),
      tlsPort: formData.get("port_tls"),
      isVerified: formData.get("testAccount") ?? 0,
      besLimiter: formData.get("besLimiter"),
      useSendWindow: formData.get("useSendWindow") ?? 0,
    }

    var addOrEditUrl = "/settings/imap/add"
    if (emailEditId) {
      addOrEditUrl = `/settings/imap/edit/${emailEditId}`
    }

    return new Promise(async () => {
      await AxiosInstance.post(addOrEditUrl, emailPostData)
        .then(function (response) {
          if (response.status === 201 || response.status === 200) {
            getCommunicationList()
            setShowAddEmail(false)
            return false
          }
        })
        .catch(function (error) {
          alert("There is some error Please try after sometime.")
        })
    })
  }

  return (
    <div className="row g-0">
      <Collapse in={showAddEmail}>
        <div className="col-lg-12 py-4 px-2" id="addNewEmailAccountContainer">
          <div className="row">
            <div className="col-lg-12 colHeading">
              <strong>
                <i className="bi bi-plus-lg" /> Add Account
              </strong>
            </div>

            <form onSubmit={submitImapSetting} method="post">
              <div className="col-lg-12 dh-block-body">
                <div className="row">
                  <div className="col-lg-12">
                    <p className="mb-0">
                      <strong>POP and IMAP Account Settings</strong>
                    </p>
                    <p>Enter the mail server setting for your account.</p>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-3 row align-items-center">
                          <p className="col-sm-12 mb-2">
                            <strong>User Information</strong>
                          </p>
                          <label htmlFor="yourName" className="col-sm-6">
                            Your Name:
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              name="owner"
                              id="yourName"
                              placeholder="Your Name"
                              className="form-control"
                              value={editEmailData.owner ?? ""}
                              onChange={e => {
                                setEditEmailData({
                                  ...editEmailData,
                                  owner: e.target.value,
                                })
                              }}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                          <label htmlFor="emailAddress" className="col-sm-6">
                            Email Address:
                          </label>
                          <div className="col-sm-6">
                            <input
                              id="emailAddress"
                              type="text"
                              name="email"
                              placeholder="Email Address"
                              className="form-control"
                              defaultValue={editEmailData.email ?? ""}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row align-items-center">
                          <p className="col-sm-12 mb-2">
                            <strong>Server Information</strong>
                          </p>
                          <label htmlFor="serverAccountType" className="col-sm-6">
                            Account Type:
                          </label>
                          <div className="col-sm-6">
                            <select
                              id="serverAccountType"
                              name="type"
                              className="form-select"
                              value={editEmailData.type || "IMAP"}
                              onChange={e => {
                                setEditEmailData({
                                  ...editEmailData,
                                  type: e.target.value,
                                })
                              }}
                            >
                              <option value="WordPress Plugin">WordPress Plugin</option>
                              <option value="IMAP">IMAP</option>
                              <option value="POP">POP</option>
                            </select>
                          </div>
                        </div>
                        <div className="mb-3 row WPRow display-none align-items-center">
                          <label htmlFor="wordpress_url" className="col-sm-6">
                            WordPress URL{" "}
                            <span
                              data-bs-toggle="tooltip"
                              data-bs-title="URL of your wordpress installation. Usually just the domain, but could be like https://YourSiteHere.com/blog or some sub-folder"
                              data-bs-original-title=""
                              title=""
                            >
                              <i className="bi bi-question-circle-fill" />
                            </span>
                          </label>
                          <div className="col-sm-6">
                            <input
                              id="wordpress_url"
                              type="text"
                              name="wUrl"
                              placeholder="WordPress URL"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ImapRow align-items-center">
                          <label htmlFor="incomingMailServer" className="col-sm-6">
                            Incoming mail server:
                          </label>
                          <div className="col-sm-6">
                            <input
                              id="incomingMailServer"
                              type="text"
                              name="incoming"
                              placeholder="Incoming mail server"
                              className="form-control"
                              defaultValue={editEmailData.incoming ?? ""}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ImapRow align-items-center">
                          <label htmlFor="outgoingMailServer" className="col-sm-6">
                            Outgoing mail server (SMTP):
                          </label>
                          <div className="col-sm-6">
                            <input
                              id="outgoingMailServer"
                              type="text"
                              name="outgoing"
                              placeholder="Outgoing mail server (SMTP)"
                              className="form-control"
                              defaultValue={editEmailData.outgoing ?? ""}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ImapRow align-items-center">
                          <p className="col-sm-12 mb-2">
                            <strong>Logon Information</strong>
                          </p>
                          <label htmlFor="logonuserName" className="col-sm-6">
                            User Name:
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              id="logonuserName"
                              name="loguserName"
                              placeholder="User Name"
                              className="form-control"
                              defaultValue={editEmailData.username ?? ""}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ImapRow align-items-center">
                          <label htmlFor="logonpassword" className="col-sm-6">
                            Password:
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="password"
                              name="logpass"
                              id="logonpassword"
                              placeholder="********"
                              className="form-control"
                              defaultValue={editEmailData.password ?? ""}
                            />
                          </div>
                        </div>
                        <div className="mb-3 row ImapRow">
                          <label className="col-sm-6 d-none d-md-table-cell">&nbsp;</label>
                          <div className="col-sm-6">
                            <input
                              type="checkbox"
                              id="rememberpass"
                              name="rememberpass"
                              defaultChecked
                              className="css-checkbox"
                            />{" "}
                            <label htmlFor="rememberpass" className="css-checkbox-label mb-0">
                              Remember password
                            </label>
                          </div>
                        </div>
                        <div className="mb-3 row ImapRow mb-0">
                          <div className="col-sm-12">
                            <input type="checkbox" id="passAuth" name="spa" className="css-checkbox" />{" "}
                            <label htmlFor="passAuth" className="css-checkbox-label mb-0">
                              Require logon using Secure Password Authentication(SPA)
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="mb-3 row ImapRow align-items-center">
                              <label htmlFor="port" className="col-sm-6">
                                Port:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  name="port"
                                  id="port"
                                  placeholder="993"
                                  value={editEmailData.port ?? "993"}
                                  className="form-control"
                                  onChange={e => {
                                    setEditEmailData({
                                      ...editEmailData,
                                      port: e.target.value,
                                    })
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="mb-3 row ImapRow align-items-center">
                              <label htmlFor="port_ssl" className="col-sm-6">
                                Port for SSL:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  name="port_ssl"
                                  id="port_ssl"
                                  placeholder="465"
                                  className="form-control"
                                  value={editEmailData.sslPort ?? "465"}
                                  onChange={e => {
                                    setEditEmailData({
                                      ...editEmailData,
                                      sslPort: e.target.value,
                                    })
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row row-cols-lg-auto g-1 align-items-center">
                          <div className="col-12">Port for TLS/STARTTLS:</div>
                          <div className="col-12">
                            <input
                              type="text"
                              name="port_tls"
                              id="port_tls"
                              placeholder="587"
                              className="form-control ml-2"
                              value={editEmailData.tlsPort ?? "587"}
                              onChange={e => {
                                setEditEmailData({
                                  ...editEmailData,
                                  tlsPort: e.target.value,
                                })
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 pt-5">
                            <p className="mb-2">
                              <strong>Logon Information</strong>
                            </p>
                            <p className="mb-2">
                              <strong>
                                We recommend that you test your account to ensure that the entries are
                                correct.
                              </strong>
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 mt-2 mb-3">
                            <a href="#" className="btn btn-default disabled">
                              Test Account Settings...
                            </a>
                          </div>

                          <div>
                            <div className="row mb-3 row-cols-sm-auto g-0 align-items-center">
                              <div className="col-12">
                                Bulk Email Speed Limiter{" "}
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>
                                      How warmed up is your email box? What's the email box server reputation?
                                      Send too fast and you'll get into spam and/or degrade your server
                                      reputation.
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    data-bs-toggle="tooltip"
                                    // data-placement="bottom"
                                    title=""
                                  >
                                    <i className="bi bi-question-circle-fill" />{" "}
                                  </span>
                                </OverlayTrigger>
                                Send
                              </div>
                              &nbsp;
                              <div className="col-12">
                                <select
                                  className="form-select"
                                  name="besLimiter"
                                  value={editEmailData.besLimiter ?? ""}
                                  onChange={e => {
                                    setEditEmailData({
                                      ...editEmailData,
                                      besLimiter: e.target.value,
                                    })
                                  }}
                                >
                                  <option value="360">1 per 6 hours</option>
                                  <option value="240">1 per 4 hours</option>
                                  <option value="180">1 per 3 hours</option>
                                  <option value="120">1 per 2 hours</option>
                                  <option value="60">1 per 1 hour</option>
                                  <option value="30">1 per 30 minutes</option>
                                  <option value="15">1 per 15 minutes</option>
                                  <option value="10">1 per 10 minutes</option>
                                  <option value="6">1 per 6 minutes</option>
                                  <option value="3">1 per 3 minutes</option>
                                  <option value="1">1 per 1 minute</option>
                                  <option value=".5">2 / minute</option>
                                  <option value=".33">3 / minute</option>
                                  <option value=".25">5 / minute</option>
                                  <option value=".14">7 / minute</option>
                                  <option value=".1">10 / minute</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="mb-3 row">
                            <div className="col-sm-12">
                              <input
                                type="checkbox"
                                defaultChecked={editEmailData.useSendWindow}
                                id="useSendWindow"
                                name="useSendWindow"
                                className="css-checkbox"
                              />
                              <label htmlFor="useSendWindow" className="css-checkbox-label mb-0">
                                Use Send Windows{" "}
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip>
                                      ~ 6:00 to 23:00 your local time. Looking more human-sent may help
                                      inboxing.
                                    </Tooltip>
                                  }
                                >
                                  <i className="bi bi-question-circle-fill" />
                                </OverlayTrigger>
                              </label>
                            </div>
                          </div>

                          <div className="col-sm-12">
                            <input
                              type="checkbox"
                              id="testAccountSetting"
                              name="testAccount"
                              defaultChecked
                              className="css-checkbox"
                              value={1}
                            />
                            <label htmlFor="testAccountSetting" className="css-checkbox-label mb-0">
                              Automatically test account setting when Next is clicked
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 text-end">
                    <button
                      type="button"
                      name="btnCancel"
                      id="btnCancelAddNewEmail"
                      className="btn btn-default"
                      onClick={() => {
                        setShowAddEmail(false)
                      }}
                    >
                      <i className="bi bi-x-lg" /> Cancel
                    </button>{" "}
                    <button type="submit" name="btnSave" className="btn btn-primary">
                      <i className="bi bi-check2" /> Save &amp; Verify
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default AddEmail
