import * as React from "react"
import { Accordion } from "react-bootstrap"
import { useEffect, useState } from "react"

import SettingsLayout from "./settingsLayout"
import Email from "./Communication/email"
import Phone from "./Communication/phone"
import AxiosInstance from "../../services/axiosInstance"
import Podcast from "./Communication/podcast"

const Communication = () => {
  const [showAddAccounting, setShowAddAccounting] = useState(false)
  const [driveList, setDriveList] = useState([])

  const getDriveList = () => {
    AxiosInstance.get(`/drive/list-sheet`).then(response => {
      setDriveList(response.data.items)
    })
  }

  useEffect(() => {
    getDriveList()
  }, [])

  return (
    <SettingsLayout title="Communication & Broadcasting" iconClass="megaphone-fill me-2" isBusinessPage="1">
      <div className="row g-0 pt-5">
        <div className="col">
          <div className="accordion" id="SwiftCloudSettings">
            <div className="accordion-item">
              <h2
                className="accordion-header accordion-button"
                data-bs-toggle="collapse"
                data-bs-target="#generalSettings"
                aria-expanded="false"
                aria-controls="generalSettings"
              >
                <i className="bi bi-megaphone-fill me-2" /> Communication & Broadcasting
              </h2>
              <div
                id="generalSettings"
                className="accordion-collapse collapse show"
                aria-labelledby="generalSettings"
                data-bs-parent="#SwiftfCloudSettings"
              >
                <div className="accordion-body">
                  <div className="row g-0 block-content border-bottom">
                    <div className="col-lg-12">
                      <Accordion>
                        <Email driveList={driveList} />
                        <Phone driveList={driveList} />
                        <Podcast />
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SettingsLayout>
  )
}

export default Communication
