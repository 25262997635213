import * as React from "react"

import Communication from "../../components/Settings/communication"
import PrivateRoute from "../../components/privateRoute"
import ReduxWrapper from "../../redux/reduxWrapper"

const CommunicationPage = props => {
  return <PrivateRoute component={Communication} location={props.location} />
}

const WrappedPage = props => <ReduxWrapper element={<CommunicationPage {...props} />} />
export default WrappedPage
