export const podcastUrlAndIconData = [
  {
    label: "Apple",
    value: "apple",
    url: "https://podcasts.apple.com/",
    icon: "apple",
  },
  {
    label: "Spotify",
    value: "spotify",
    url: "https://open.spotify.com/",
    icon: "spotify",
  },
  {
    label: "Google",
    value: "google",
    url: "https://www.google.com/podcasts",
    icon: "google",
  },
  {
    label: "YouTube",
    value: "youtube",
    url: "https://www.youtube.com/channel",
    icon: "youtube",
  },
  {
    label: "Amazon Music",
    value: "amazonMusic",
    url: "https://music.amazon.com/podcasts/",
    icon: "amazon-music",
  },
  {
    label: "Player FM",
    value: "playerFm",
    url: "https://player.fm/series/",
    icon: "player-fm",
  },
  {
    label: "Pocket Casts",
    value: "pocketCasts",
    url: "https://pca.st/",
    icon: "pocket-casts",
  },
  {
    label: "Overcast",
    value: "overCast",
    url: "https://overcast.fm/",
    icon: "overcast",
  },
  {
    label: "Soundcloud",
    value: "soundcloud",
    url: "https://soundcloud.com/",
    icon: "sound-cloud",
  },
  {
    label: "Deezer",
    value: "deezer",
    url: "https://www.deezer.com/show/",
    icon: "deezer",
  },
  {
    label: "Podcast Addict",
    value: "podcastAddict",
    url: "https://podcastaddict.com/podcast/",
    icon: "podcast-addict",
  },
  {
    label: "Gaana",
    value: "gaana",
    url: "https://podcasts.gaana.com/feed/",
    icon: "gaana",
  },
  {
    label: "Podchaser",
    value: "podchaser",
    url: "https://www.podchaser.com/podcasts/",
    icon: "podchaser",
  },
  {
    label: "Podcast Index",
    value: "podcastIndex",
    url: "https://podcastindex.org/podcast/",
    icon: "podcast-index",
  },
  {
    label: "Castro",
    value: "castro",
    url: "https://castro.fm/podcast/",
    icon: "castro",
  },
  {
    label: "Listen Notes",
    value: "listenNotes",
    url: "https://www.listennotes.com/podcasts/",
    icon: "listen-notes",
  },
]
