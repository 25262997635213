import React, { useState, useEffect } from "react"
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap"
import AddEmail from "./addEmail"
import AxiosInstance from "../../../services/axiosInstance"
import { getUserName } from "../../Drive/Doc/Service/docHelper"

const Email = ({ driveList }) => {
  const [showAddEmail, setShowAddEmail] = useState(false),
    [settingCommunication, setSettingCommunication] = useState([]),
    [editEmailData, setEditEmailData] = useState([]),
    [emailOptOut, setEmailOptOut] = useState(""),
    [formDriveList, setFormDriveList] = useState(""),
    [newsletterDescription, setNewsletterDescription] = useState(""),
    [newsletterDescriptionId, setNewsletterDescriptionId] = useState(""),
    [newsletterForm, setNewsletterForm] = useState(""),
    [newsletterFormId, setNewsletterFormId] = useState("")

  const getCommunicationList = () => {
    AxiosInstance.get(`/settings/imap`)
      .then(response => {
        if (response.data.items.length > 0) {
          setSettingCommunication(response.data.items)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleEditEmail = id => {
    AxiosInstance.get(`/settings/imap/detail/${id}`)
      .then(response => {
        setEditEmailData(response.data.setting)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleDeleteEmail = id => {
    AxiosInstance.delete(`/settings/imap/${id}`)
      .then(response => {
        if (response.status === 200) {
          getCommunicationList()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handlePrimaryEmail = id => {
    AxiosInstance.post(`/settings/imap/make-primary/${id}`)
      .then(response => {
        if (response.status === 200) {
          getCommunicationList()
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const saveEmailOptOuts = id => {
    if (id === "") {
      return
    }
    AxiosInstance.post(`/settings/imap/save-email-opt-outs/${id}`)
      .then(response => {
        if (response.status === 200) {
          setEmailOptOut(id)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getEmailOptOut = id => {
    AxiosInstance.get(`/settings/general-setting/email-opt-out`)
      .then(response => setEmailOptOut(response.data.data))
      .catch(error => {
        console.log(error)
      })
  }

  const handleSubmit = (type, value, id) => {
    if (id === "" || value === "" || type === "") {
      return false
    }

    if (type === "NewsletterSubscriptionForm" && value === newsletterForm) {
      return false
    }

    if (type === "NewsletterDescription" && value === newsletterDescription) {
      return false
    }

    let url = "/settings/general-setting/add"
    if (id) {
      url = `/settings/general-setting/edit/${id}`
    }

    AxiosInstance.post(url, {
      type: type,
      value: value,
      privacy: "public",
    })
      .then(response => {
        getNewsletterDetails()
      })
      .catch(function (error) {})
  }

  const getNewsletterDetails = async () => {
    AxiosInstance.get("/settings/general-setting/newsletter-data").then(response => {
      response.data.data.map(data => {
        if (data.type === "NewsletterSubscriptionForm") {
          setNewsletterForm(data.value)
          setNewsletterFormId(data.id)
        }

        if (data.type === "NewsletterDescription") {
          setNewsletterDescription(data.value)
          setNewsletterDescriptionId(data.id)
        }
      })
    })
  }

  const getFormDriveList = () => {
    AxiosInstance.get(`/drive/list`, {
      params: {
        type: "form",
      },
    }).then(response => {
      setFormDriveList(response.data.items)
    })
  }

  useEffect(() => {
    getCommunicationList()
    getEmailOptOut()
    getFormDriveList()
    getNewsletterDetails()
  }, [])

  return (
    <Accordion.Item eventKey="0">
      <Accordion.Header>
        <i className="bi bi-envelope-fill" /> Email
      </Accordion.Header>
      <Accordion.Body className={"px-3"}>
        <div className="row">
          <div className="col-lg-10 col-sm-12 col-md-12 col-xs-12 mx-auto">
            <div className="row">
              <div className="col-lg-8 col-sm-12 col-md-12 col-xs-12 mx-auto">
                <div className="row mb-3 align-items-center">
                  <div className="col-sm-12 ms-sm-5 ps-sm-4 text-center">
                    <a
                      className="dashedLinked "
                      href={`https://newsletter.SwiftCRM.com/${getUserName()}`}
                      target="_blank"
                    >
                      newsletter.SwiftCRM.com/{getUserName()}
                    </a>
                    <a
                      className="ms-2"
                      href={`https://newsletter.SwiftCRM.com/${getUserName()}/rss.xml`}
                      target="_blank"
                    >
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip className="ms-1 d-inline-block">RSS Feed</Tooltip>}
                      >
                        <i className="bi bi-rss-fill" />
                      </OverlayTrigger>
                    </a>
                  </div>
                </div>

                <div className="row mb-3 align-items-center">
                  <div className="col-sm-4 setting_label">
                    <label htmlFor="podcast_title" className="col-form-label">
                      Newsletter Subscription Form
                    </label>
                  </div>
                  <div className="col-sm-8">
                    <select
                      className="form-select"
                      value={newsletterForm}
                      onChange={e => {
                        handleSubmit("NewsletterSubscriptionForm", e.target.value, newsletterFormId)
                      }}
                    >
                      <option value="">== FORM ==</option>
                      {formDriveList.length > 0 &&
                        formDriveList.map(FormData => {
                          return (
                            <option key={FormData.id} value={FormData.id}>
                              {FormData.nameDisplay}
                            </option>
                          )
                        })}
                    </select>
                  </div>
                </div>
                <div className="row mb-3 align-items-center">
                  <div className="col-sm-4 setting_label">
                    <label htmlFor="podcast_desc" className="col-form-label">
                      Description
                    </label>
                  </div>
                  <div className="col-sm-8">
                    <textarea
                      className="form-control"
                      rows={10}
                      name="podcast_desc"
                      id="podcast_desc"
                      placeholder="Newsletter Description"
                      defaultValue={newsletterDescription}
                      onBlur={e => {
                        handleSubmit("NewsletterDescription", e.target.value, newsletterDescriptionId)
                      }}
                    />
                  </div>
                </div>

                <div className="row g-0">
                  <div className="col-lg-12 col-md-10 mx-auto pb-2 ">
                    <div className="input-group mb-1 ">
                      <span className="input-group-text col-lg-4  ms-lg-2 justify-content-center">
                        Opt-Outs / Block List
                      </span>
                      <select
                        className="form-select"
                        value={emailOptOut}
                        onChange={e => {
                          saveEmailOptOuts(e.target.value)
                        }}
                      >
                        <option value="">== SPREADSHEET ==</option>
                        {driveList.length > 0 &&
                          driveList.map(sheetsData => {
                            return (
                              <option key={sheetsData.id} value={sheetsData.id}>
                                {sheetsData.nameDisplay}
                              </option>
                            )
                          })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-3 ms-4 ps-1 align-items-center">
          <div className="col-lg-12 ms-lg-2 text-center">
            <a
              className="dashedLinked"
              href={`${process.env.GATSBY_PREVIEW_URL}/${getUserName()}/optout`}
              target="_blank"
            >
              {`${process.env.GATSBY_PREVIEW_URL.replace(/^https?:\/\//, "")}/${getUserName()}/optout`}
            </a>
          </div>
        </div>
        <AddEmail
          showAddEmail={showAddEmail}
          setShowAddEmail={setShowAddEmail}
          editEmailData={editEmailData}
          setEditEmailData={setEditEmailData}
          getCommunicationList={getCommunicationList}
        />
        <div className="row mb-2 align-items-center">
          <div className="col-md-6">
            <h4 className="mb-0">
              <i className="bi bi-envelope-fill" /> Email Accounts
            </h4>
          </div>
          <div className="col-md-6 text-end">
            <button
              className="btn btn-primary btnAddNewEmail"
              onClick={() => {
                setShowAddEmail(!showAddEmail)
                setEditEmailData([])
              }}
            >
              <i className="bi bi-plus-lg" /> Add New
            </button>
          </div>
        </div>
        <div className="table-responsive">
          <table id="tblEmailList" className="table table-striped dataTable no-footer" width="100%">
            <thead>
              <tr>
                <th width="30%">Type</th>
                <th width="50%">Account</th>
                <th width="20%" />
              </tr>
            </thead>
            <tbody>
              {settingCommunication.length === 0 && (
                <tr>
                  <td colSpan={2} className="text-center">
                    No Email Accounts Added Yet. Want to{" "}
                    <a
                      href="#"
                      style={{ color: "#125fad" }}
                      onClick={() => {
                        setShowAddEmail(!showAddEmail)
                      }}
                    >
                      click here & add one
                    </a>{" "}
                    now?
                  </td>
                </tr>
              )}

              {settingCommunication.map((comminicationValue, comminicationIndex) => {
                return (
                  <tr key={comminicationIndex}>
                    <td>{comminicationValue.type.toUpperCase()}</td>
                    <td>{comminicationValue.email}</td>
                    <td className="text-end tableColAction d-none d-md-table-cell">
                      {!comminicationValue.isDefault && (
                        <OverlayTrigger overlay={<Tooltip>Make Primary</Tooltip>}>
                          <a
                            className="btn btn-secondary"
                            onClick={() => {
                              handlePrimaryEmail(comminicationValue.id)
                            }}
                          >
                            <i className="bi bi-check2" />
                          </a>
                        </OverlayTrigger>
                      )}{" "}
                      <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>}>
                        <a
                          href="#"
                          className="btn btn-edit"
                          onClick={e => {
                            e.preventDefault()
                            handleEditEmail(comminicationValue.id)
                            setShowAddEmail(true)
                          }}
                        >
                          <i className="bi bi-pencil-fill"></i>
                        </a>
                      </OverlayTrigger>{" "}
                      <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                        <a
                          className="btn btn-delete"
                          onClick={() => {
                            handleDeleteEmail(comminicationValue.id)
                          }}
                        >
                          <i className="bi bi-trash-fill" />
                        </a>
                      </OverlayTrigger>{" "}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default Email
