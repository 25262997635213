import React, { useEffect, useState, useRef } from "react"
import { Accordion } from "react-bootstrap"
import { deleteFile } from "../AboutYourBusiness/helper"
import AxiosInstance from "../../../services/axiosInstance"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { getLoginUserId } from "../../../services/auth"
import { getUserName } from "../../Drive/Doc/Service/docHelper"
import { podcastUrlAndIconData } from "./podcastHelper"

const Podcast = () => {
  const [podcastSettingId, setPodcastSettingId] = useState(""),
    [podcastTitleId, setPodcastTitleId] = useState(""),
    [podcastDescriptionId, setPodcastDescriptionId] = useState(""),
    [podcastPhotoId, setPodcastPhotoId] = useState(""),
    [podcastSettingValue, setPodcastSettingValue] = useState("On"),
    [podcastTitle, setPodcastTitle] = useState(""),
    [podcastDescription, setPodcastDescription] = useState(""),
    [podcastCoverPhoto, setPodcastCoverPhoto] = useState(""),
    [file, setFile] = useState(null),
    [uploadFile, setUploadFile] = useState(false),
    [isAddPodcast, setIsAddPodcast] = useState(false),
    [podcastListData, setPodcastListData] = useState([]),
    [podcastEditId, setPodcastEditId] = useState(""),
    [podcastEditType, setPodcastEditType] = useState(""),
    [podcastEditValue, setPodcastEditValue] = useState("")

  const handleSubmit = (type, value, id) => {
    if (type === "PodcastTitle" && value === podcastTitle) {
      return false
    }

    if (type === "PodcastDescription" && value === podcastDescription) {
      return false
    }

    if (type === "PodcastSetting") {
      value = value ? "On" : "Off"
    }

    let url = "/settings/general-setting/add"
    if (id) {
      url = `/settings/general-setting/edit/${id}`
    }

    AxiosInstance.post(url, {
      type: type,
      value: value,
      privacy: "public",
    })
      .then(response => {
        getPodcastDetails()
      })
      .catch(function (error) {})
  }

  const getPodcastDetails = async () => {
    AxiosInstance.get("/settings/general-setting/podcast-data").then(response => {
      response.data.data.map(data => {
        if (data.type === "PodcastSetting") {
          setPodcastSettingValue(data.value)
          setPodcastSettingId(data.id)
        }

        if (data.type === "PodcastTitle") {
          setPodcastTitle(data.value)
          setPodcastTitleId(data.id)
        }
        if (data.type === "PodcastDescription") {
          setPodcastDescription(data.value)
          setPodcastDescriptionId(data.id)
        }
        if (data.type === "PodcastCoverPhoto") {
          setPodcastCoverPhoto(data.value)
          setPodcastPhotoId(data.id)
        }
      })
    })
  }

  const onPhotoChange = e => {
    const imageRequest = e.target.files[0]
    if (imageRequest && !imageRequest.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      alert("Only JPG, PNG, or GIF image types are allowed.")
      return false
    }
    setFile(e.target.files[0])
    setUploadFile(true)
  }

  const uploadCoverPhoto = () => {
    return new Promise(async () => {
      let formData = new FormData()
      formData.append("ProfilePic", file)
      formData.append("user_id", getLoginUserId())
      formData.append("parent_id", "0")
      await AxiosInstance.post(`/settings/general-setting/upload-file-r2`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          if (response.status === 201) {
            handleSubmit("PodcastCoverPhoto", response.data.profilePic.filePath, podcastPhotoId)
          }
        })
        .catch(function (error) {})
    })
  }

  const savePodcastChannel = e => {
    e.preventDefault()

    const formData = new FormData(e.target)

    if (!formData.get("podcast_channel") && !podcastEditType) {
      alert("Please select Podcast Channel.")
      return false
    }

    if (podcastEditId) {
      var requestData = {
        type: podcastEditType,
        value: podcastEditValue,
      }
      var url = `settings/podcast/edit/${podcastEditId}`
    } else {
      var requestData = {
        type: formData.get("podcast_channel"),
        value: formData.get("podcast_url"),
      }
      var url = `settings/podcast/add`
    }

    return new Promise(async () => {
      await AxiosInstance.post(url, requestData)
        .then(response => {
          if (response.status === 201 || response.status === 200) {
            getPodcastListData()
            setPodcastEditId("")
            setPodcastEditType("")
            setPodcastEditValue("")
            return false
          }
        })
        .catch(function (error) {})
    })
  }

  const getPodcastListData = async () => {
    AxiosInstance.get("/settings/podcast").then(response => {
      setPodcastListData(response.data.items)
    })
  }

  const deletePodcastData = deleteId => {
    if (!window.confirm("Delete this Podcast?")) {
      return false
    }

    return new Promise(async () => {
      await AxiosInstance.delete(`settings/podcast/` + deleteId).then(response => {
        getPodcastListData()
        // return response
      })
    })
  }

  const photo = useRef(null)
  const openSelectPhotoBox = () => {
    photo.current.click()
  }

  useEffect(() => {
    getPodcastDetails()
    getPodcastListData()

    if (uploadFile) {
      setUploadFile(false)
      uploadCoverPhoto()
    }
  }, [uploadFile])

  return (
    <Accordion.Item eventKey="7">
      <Accordion.Header>
        <i className="bi bi-broadcast-pin" /> Podcast
      </Accordion.Header>
      <Accordion.Body>
        <div className="row">
          <div className="col-lg-10 col-sm-12 col-md-12 col-xs-12 mx-auto">
            <div className="row">
              <div className="col-lg-8 col-sm-12 col-md-12 col-xs-12 mx-auto">
                <div className="row mb-3 align-items-center">
                  <div className="col-sm-4 setting_label">
                    <label htmlFor="podcast_toggle" className="col-form-label">
                      Podcast is
                    </label>
                  </div>
                  <div className="col-sm-8 adbook-user-info">
                    <div className="toggleSwitch">
                      <input
                        type="checkbox"
                        defaultValue={1}
                        name="podcast_toggle"
                        id="podcast_toggle"
                        className="podcast_toggle general_switch"
                        key={podcastSettingValue}
                        defaultChecked={podcastSettingValue === "On"}
                        onChange={e => {
                          handleSubmit("PodcastSetting", e.target.checked, podcastSettingId)
                        }}
                      />
                      <label />
                    </div>
                  </div>
                </div>
                {podcastSettingValue === "On" && (
                  <form className="form-horizontal" id="frmPodcast">
                    <div className="row mb-3 align-items-center">
                      <div className="col-lg-12 text-center">
                        <a
                          className="dashedLinked"
                          href={`https://podcast.SwiftCRM.com/${getUserName()}`}
                          target="_blank"
                        >
                          podcast.SwiftCRM.com/{getUserName()}
                        </a>
                        <a
                          className="ms-2"
                          href={`https://podcast.SwiftCRM.com/${getUserName()}/rss.xml`}
                          target="_blank"
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip className="ms-1 d-inline-block">RSS Feed</Tooltip>}
                          >
                            <i className="bi bi-rss-fill" />
                          </OverlayTrigger>
                        </a>
                      </div>
                    </div>
                    <div className="row mb-3 align-items-center">
                      <div className="col-sm-4 setting_label">
                        <label htmlFor="company_logo" className="col-form-label">
                          Podcast Cover Photo{" "}
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip className="ms-1 d-inline-block">
                                Recommended: 200px wide by 200px high - jpg, png, or gif
                              </Tooltip>
                            }
                          >
                            <i className="bi bi-question-circle-fill" />
                          </OverlayTrigger>
                        </label>
                      </div>
                      <div className="col-sm-8">
                        <div className="podcast_logo_container position-relative">
                          <img
                            src={`${process.env.GATSBY_WRANGLER_URL}${
                              podcastCoverPhoto ? podcastCoverPhoto : "StaticImages/no-image.jpg"
                            }`}
                            style={{ maxWidth: "200px", height: "200px" }}
                            className="img-fluid"
                          />
                          <input type="file" ref={photo} onChange={onPhotoChange} hidden />
                          <button
                            type="button"
                            className="btn btnEditPodcastPhoto"
                            onClick={openSelectPhotoBox}
                          >
                            <i className="bi bi-pencil-fill" />
                          </button>
                          {podcastCoverPhoto && (
                            <button
                              type="button"
                              className="btn btnDeletePodcastPhoto"
                              onClick={() =>
                                deleteFile({
                                  id: podcastPhotoId,
                                  setValue: setPodcastPhotoId,
                                  setId: setPodcastCoverPhoto,
                                })
                              }
                            >
                              <i className="bi bi-x-lg" />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3 align-items-center">
                      <div className="col-sm-4 setting_label">
                        <label htmlFor="podcast_title" className="col-form-label">
                          Title
                        </label>
                      </div>
                      <div className="col-sm-8">
                        <input
                          type="text"
                          className="form-control"
                          id="podcast_title"
                          name="podcast_title"
                          placeholder="Podcast Title"
                          defaultValue={podcastTitle}
                          onBlur={e => {
                            handleSubmit("PodcastTitle", e.target.value, podcastTitleId)
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mb-3 align-items-center">
                      <div className="col-sm-4 setting_label">
                        <label htmlFor="podcast_desc" className="col-form-label">
                          Description
                        </label>
                      </div>
                      <div className="col-sm-8">
                        <textarea
                          className="form-control"
                          rows={10}
                          name="podcast_desc"
                          id="podcast_desc"
                          placeholder="Podcast Description"
                          defaultValue={podcastDescription}
                          onBlur={e => {
                            handleSubmit("PodcastDescription", e.target.value, podcastDescriptionId)
                          }}
                        />
                      </div>
                    </div>
                  </form>
                )}
              </div>
              {podcastSettingValue === "On" && (
                <form id="frmPodcastChannel" onSubmit={savePodcastChannel} method="POST">
                  <div className="row my-3 align-items-center podcastListContainer">
                    <div className="col-sm-12">
                      <div className="row">
                        {isAddPodcast && (
                          <div
                            className="col-lg-6 col-md-8 mx-auto py-4 display-block"
                            id="addNewPodcastContainer"
                          >
                            <div className="row">
                              <div className="col-lg-12 colHeading">
                                <strong>
                                  <i className="bi bi-plus-lg" /> {podcastEditId ? "Edit" : "Add"} Podcast
                                </strong>
                              </div>

                              <div className="col-lg-12 dh-block-body">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="row">
                                      <div className="col-sm-12">
                                        <div className="mb-3">
                                          <select
                                            className="form-select podcast_channel"
                                            value={podcastEditType}
                                            name="podcast_channel"
                                            onChange={e => {
                                              const podcastData = podcastUrlAndIconData.find(
                                                podcast => podcast.value === e.target.value
                                              )
                                              setPodcastEditType(e.target.value)
                                              setPodcastEditValue(podcastData.url)
                                            }}
                                          >
                                            <option value="">== Podcast Channel Listener ==</option>
                                            {podcastUrlAndIconData.map((podcastValue, podcastIndex) => {
                                              return (
                                                <option key={podcastIndex} value={podcastValue.value}>
                                                  {podcastValue.label}
                                                </option>
                                              )
                                            })}
                                          </select>
                                        </div>
                                        <div className="mb-3">
                                          <input
                                            type="url"
                                            name="podcast_url"
                                            id="podcast_url"
                                            className="form-control"
                                            placeholder="https://"
                                            value={podcastEditValue ? podcastEditValue : "https://"}
                                            onChange={e => {
                                              setPodcastEditValue(e.target.value)
                                            }}
                                          />
                                        </div>
                                        <div className="row">
                                          <div className="col-sm-12 text-end">
                                            <button
                                              type="button"
                                              name="btnCancel"
                                              id="btnCancelAddNewPodcast"
                                              className="btn btn-default"
                                              onClick={() => {
                                                setIsAddPodcast(false)
                                              }}
                                            >
                                              <i className="bi bi-x-lg" /> Cancel
                                            </button>{" "}
                                            <button
                                              type="submit"
                                              name="btnSave"
                                              id="btnSavePodcast"
                                              className="btn btn-primary"
                                            >
                                              <i className="bi bi-check2" /> Save
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row mb-2 align-items-center">
                        <div className="col-md-6">
                          <h4 className="mb-0">
                            <i className="bi bi-broadcast-pin" /> Podcast
                          </h4>
                        </div>
                        <div className="col-md-6 text-end">
                          <button
                            className="btn btn-primary btnAddNewPodcast"
                            onClick={() => {
                              setIsAddPodcast(true)
                              setPodcastEditId("")
                              setPodcastEditType("")
                              setPodcastEditValue("")
                            }}
                          >
                            <i className="bi bi-plus-lg" /> Add New
                          </button>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table id="tblPodcast" className="table dataTable no-footer" width="100%">
                          <thead>
                            <tr>
                              <th width="5%">&nbsp;</th>
                              <th width="70%">URL</th>
                              <th width="25%" className="text-end d-none d-lg-table-cell" />
                            </tr>
                          </thead>
                          <tbody>
                            {podcastListData.length === 0 && (
                              <tr>
                                <td colSpan={3} className="text-center">
                                  No Podcast data added yet. Want to
                                  <a
                                    style={{ color: "#125fad", cursor: "pointer" }}
                                    onClick={() => {
                                      setIsAddPodcast(true)
                                    }}
                                  >
                                    {" "}
                                    click here & add one{" "}
                                  </a>
                                  now?
                                </td>
                              </tr>
                            )}

                            {podcastListData.map((podcastValue, podcastIndex) => {
                              const findPodcast = podcastUrlAndIconData.find(
                                podcast => podcast.value === podcastValue.type
                              )
                              return (
                                <tr key={podcastIndex}>
                                  <td className="text-center tableColChkDel">
                                    <div className="tableColChkDelBox">
                                      <input
                                        type="checkbox"
                                        name="fileid[]"
                                        id={`Pod_ID${podcastValue.id}`}
                                        className="css-checkbox"
                                      />
                                      <label
                                        htmlFor={`Pod_ID${podcastValue.id}`}
                                        className="css-checkbox-label"
                                      />
                                      <span className="tableColChkSpan btn-secondary">
                                        <i className={`bi bi-${findPodcast.icon}`} />
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <a href={podcastValue.value} className="dashedLinked" target="_blank">
                                      {podcastValue.value}
                                    </a>
                                  </td>
                                  <td className="text-end tableColAction d-none d-lg-table-cell">
                                    <a
                                      className="btn btn-edit"
                                      onClick={() => {
                                        setIsAddPodcast(true)
                                        setPodcastEditId(podcastValue.id)
                                        setPodcastEditType(podcastValue.type)
                                        setPodcastEditValue(podcastValue.value)
                                      }}
                                    >
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip className="ms-1 d-inline-block">Edit</Tooltip>}
                                      >
                                        <i className="bi bi-pencil-fill" />
                                      </OverlayTrigger>
                                    </a>{" "}
                                    <a
                                      className="btn btn-delete"
                                      onClick={() => {
                                        deletePodcastData(podcastValue.id)
                                      }}
                                    >
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip className="ms-1 d-inline-block">Delete</Tooltip>}
                                      >
                                        <i className="bi bi-trash-fill" />
                                      </OverlayTrigger>
                                    </a>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

export default Podcast
